import styled from 'styled-components'
import { IconButton } from '../../design-system/utils'

export const OverLay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${(p) => p.theme.colors.black};
  opacity: 0.5;
  z-index: 99;
`

export const ModalCard = styled.div<{ width: string; absolute: boolean }>`
  position: ${(p) => (p.absolute ? 'absolute' : 'fixed')};
  left: 0;
  top: 20px;
  right: 0;
  margin: auto;
  z-index: 100;
  background: ${(p) => p.theme.colors.white};
  width: ${(p) => (p.width ? p.width : '500px')};
  padding: ${(p) => p.theme.spacing.md};
  border-radius: ${(p) => p.theme.borderRadius};
  box-shadow: ${(p) => p.theme.boxShadow};
  overflow-y: scroll;
  max-height: 90vh;
`

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: ${(p) => p.theme.spacing.sm};
  top: ${(p) => p.theme.spacing.sm};
`
