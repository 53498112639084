import axios from 'axios'

type CreateCompetition = {
  file: File
  title: string
  startDateTime: string
  endDateTime: string
  description?: string
  mainPrize: string
  status: string
  secondaryPrize?: string
  prizeQuantity?: number
  url?: string
}
type UpdateCompetition = CreateCompetition & { id: string }
const getAllCompetitions = () => {
  return axios.get('/api/admin/competitions', { withCredentials: true })
}

const deleteCompetition = (id: string | number) => {
  return axios.delete(`/api/admin/competitions/${id}`, {
    withCredentials: true,
  })
}

const createCompetition = ({
  file,
  title,
  startDateTime,
  endDateTime,
  description,
  mainPrize,
  status,
  secondaryPrize,
  prizeQuantity,
  url,
}: CreateCompetition) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('title', title)
  formData.append('startDateTime', startDateTime)
  formData.append('endDateTime', endDateTime)
  formData.append('description', description)
  formData.append('mainPrize', mainPrize)
  formData.append('secondaryPrize', secondaryPrize)
  formData.append('status', status)
  formData.append('prizeQuantity', String(prizeQuantity))
  formData.append('url', url)

  return axios.post('/api/admin/competitions', formData, {
    withCredentials: true,
  })
}

const editCompetition = ({
  id,
  file,
  title,
  startDateTime,
  endDateTime,
  description,
  mainPrize,
  status,
  secondaryPrize,
  prizeQuantity,
  url,
}: UpdateCompetition) => {
  const formData = new FormData()
  if (file) {
    formData.append('file', file)
  }

  formData.append('title', title)
  formData.append('startDateTime', startDateTime)
  formData.append('endDateTime', endDateTime)
  formData.append('description', description)
  formData.append('mainPrize', mainPrize)
  formData.append('secondaryPrize', secondaryPrize)
  formData.append('status', status)
  formData.append('prizeQuantity', String(prizeQuantity))
  formData.append('url', url)

  return axios.put(`/api/admin/competitions/${id}`, formData, {
    withCredentials: true,
  })
}

const competitionsApi = {
  getAllCompetitions,
  deleteCompetition,
  createCompetition,
  editCompetition,
}

export default competitionsApi
