import React, { useState } from 'react'
import moment from 'moment'
import { sortBy } from 'lodash'
import { Link } from 'react-router-dom'
import {
  Heading3Bold,
  Paragraph2,
  Paragraph2Bold,
} from '../../../design-system/typography'
import {
  IconButton,
  Line,
  DangerButton,
  TextButton,
} from '../../../design-system/utils'
import CloseIcon from '../../../svgs/CloseIcon'
import EditIcon from '../../../svgs/EditIcon'
import Roles from '../../../types/Roles'
import User from '../../../types/User'
import dateConverter from '../../../utils/DateConverter'
import EditUser from '../EditUser'
import Modal from '../../../components/Modal'
import {
  AdminLabel,
  CloseButton,
  DetailsCard,
  NameWrapper,
  UserDataGrid,
  ButtonsWrapper,
} from './style'
import usersApi from '../../../api/users'
import UserSessions from '../UserSessions'

type UserDetailsProps = {
  user: User
  closeCard: () => void
  triggerUpdate: () => void
}

function UserDetails({ user, closeCard, triggerUpdate }: UserDetailsProps) {
  const [editMode, setEditMode] = useState(false)
  const [isShowDeleteModal, toggleDeleteModal] = useState(false)

  const handleUserDelete = async () => {
    try {
      await usersApi.deleteUser(user?.id)
      closeCard()
      triggerUpdate()
    } catch (err) {
      closeCard()
    }
  }
  const getCardContent = () => {
    if (editMode) {
      return (
        <EditUser
          user={user}
          setEditMode={setEditMode}
          triggerUpdate={triggerUpdate}
        />
      )
    }

    return (
      <>
        <NameWrapper>
          <Heading3Bold>
            {user.firstName} {user.lastName}
          </Heading3Bold>
          <IconButton onClick={() => setEditMode(true)}>
            <EditIcon />
          </IconButton>
          {user.role === Roles.ADMIN && <AdminLabel>Administrator</AdminLabel>}
        </NameWrapper>
        <UserDataGrid>
          <Paragraph2Bold>Email</Paragraph2Bold>
          <Paragraph2>{user.email}</Paragraph2>
          <Paragraph2Bold>User ID</Paragraph2Bold>
          <Paragraph2>{user.id}</Paragraph2>
          <Paragraph2Bold>Registration date</Paragraph2Bold>
          <Paragraph2>{dateConverter(user.createdAt)}</Paragraph2>
          <Paragraph2Bold>Modified</Paragraph2Bold>
          <Paragraph2>{dateConverter(user.updatedAt)}</Paragraph2>
          <Paragraph2Bold>Type of user</Paragraph2Bold>
          <Paragraph2>{user?.accountStatus}</Paragraph2>

          <Paragraph2Bold>Training plans</Paragraph2Bold>
          <Paragraph2>
            <Link to={`/users/${user?.id}/plans`} className="underline pointer">
              Plans Page
            </Link>
          </Paragraph2>
          <Paragraph2Bold>Training plan ID</Paragraph2Bold>
          <Paragraph2>
            {user?.trainingPlans[0]?.id ? (
              <Link
                to={`/users/${user?.id}/plans/${user?.trainingPlans[0]?.id}`}
                className="underline pointer"
              >
                {user?.trainingPlans[0]?.id}
              </Link>
            ) : (
              '-'
            )}
          </Paragraph2>

          <Paragraph2Bold>Training plan</Paragraph2Bold>
          <Paragraph2>
            {user?.trainingPlans[0]?.targetDistance &&
            user?.trainingPlans[0].plannedWeeks
              ? `${user?.trainingPlans[0]?.targetDistance} / ${user?.trainingPlans[0]?.plannedWeeks} weeks`
              : '-'}
          </Paragraph2>

          <Paragraph2Bold>Training plan start date</Paragraph2Bold>
          <Paragraph2>
            {user?.trainingPlans[0]?.startDate
              ? user?.trainingPlans[0]?.startDate
              : '-'}
          </Paragraph2>

          <Paragraph2Bold>Training plan target date</Paragraph2Bold>
          <Paragraph2>
            {user?.trainingPlans[0]?.targetDate
              ? user?.trainingPlans[0]?.targetDate
              : '-'}
          </Paragraph2>

          <Paragraph2Bold>Training plan level</Paragraph2Bold>
          <Paragraph2>
            {user?.trainingPlans[0]?.level
              ? user?.trainingPlans[0]?.level
              : '-'}
          </Paragraph2>

          <Paragraph2Bold>Training plan goal</Paragraph2Bold>
          <Paragraph2>
            {user?.trainingPlans[0]?.goal ? user?.trainingPlans[0]?.goal : '-'}
          </Paragraph2>

          <Paragraph2Bold>Training plan template ID</Paragraph2Bold>
          <Paragraph2>
            {user?.trainingPlans[0]?.templatePlanId
              ? user?.trainingPlans[0]?.templatePlanId
              : '-'}
          </Paragraph2>

          <Paragraph2Bold>Average Pace</Paragraph2Bold>
          <Paragraph2>
            {user?.trainingPlans[0]?.avgPaceMsPerKm
              ? `${moment
                  .utc(user?.trainingPlans[0]?.avgPaceMsPerKm)
                  .format('mm:ss')} min/km`
              : '-'}
          </Paragraph2>
          <Paragraph2Bold>Threshold Pace</Paragraph2Bold>
          <Paragraph2>
            {user?.trainingPlans[0]?.thrPaceMsPerKm
              ? `${moment
                  .utc(user?.trainingPlans[0]?.thrPaceMsPerKm)
                  .format('mm:ss')} min/km`
              : '-'}
          </Paragraph2>
          <Paragraph2Bold>Target Time</Paragraph2Bold>
          <Paragraph2>
            {user?.trainingPlans[0]?.targetTimeMs
              ? `${moment
                  .utc(user?.trainingPlans[0]?.targetTimeMs)
                  .format('HH:mm:ss')} min`
              : '-'}
          </Paragraph2>
          <Paragraph2Bold>Club</Paragraph2Bold>
          <Paragraph2>{user?.club?.name || '-'}</Paragraph2>
          <Paragraph2Bold>Actions</Paragraph2Bold>
          <Paragraph2>
            <DangerButton onClick={() => toggleDeleteModal(true)}>
              Delete User
            </DangerButton>
          </Paragraph2>
        </UserDataGrid>
      </>
    )
  }

  return (
    <DetailsCard>
      <CloseButton onClick={closeCard}>
        <CloseIcon />
      </CloseButton>
      {getCardContent()}
      <Line />
      <UserSessions
        sessions={sortBy(
          user?.trainingPlans[0]?.sessions?.filter(
            (item) =>
              moment(item?.date).isAfter(new Date()) &&
              moment(item?.date).isBefore(moment().add(7, 'days'))
          ) || [],
          ['date']
        )}
      />

      {isShowDeleteModal && (
        <Modal onClose={() => toggleDeleteModal(false)} width="695px">
          <Heading3Bold>
            Are you sure you want to delete this user?
          </Heading3Bold>
          <ButtonsWrapper>
            <TextButton onClick={() => toggleDeleteModal(false)}>
              Cancel
            </TextButton>
            <DangerButton onClick={handleUserDelete}>Delete User</DangerButton>
          </ButtonsWrapper>
        </Modal>
      )}
    </DetailsCard>
  )
}

export default UserDetails
