import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import competitionsApi from '../../api/competitions'
import { Heading1Bold } from '../../design-system/typography'
import { PageWrapper } from '../../design-system/utils'
import CreateCompetition from './CreateCompetition'
import CompetitionCard from './CompetitionCard'
import { CreateButton, TitleWrapper } from './style'
import withAuthorization from '../../components/HOC/withAuthorization'

function Competitions() {
  const [competitions, setCompetitions] = useState([])
  const [triggerUpdate, setTriggerUpdate] = useState(false)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [unauthorized, setUnauthorized] = useState(false)

  useEffect(() => {
    competitionsApi
      .getAllCompetitions()
      .then((res) => setCompetitions(res.data))
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          setUnauthorized(true)
        }
      })
  }, [triggerUpdate])

  if (unauthorized) {
    return <Redirect to="/login" />
  }

  return (
    <PageWrapper>
      <TitleWrapper>
        <Heading1Bold>Competitions</Heading1Bold>
        <CreateButton secondary onClick={() => setCreateModalOpen(true)}>
          + Create a competition
        </CreateButton>
      </TitleWrapper>
      {competitions.map((competition) => (
        <CompetitionCard
          competition={competition}
          key={competition.id}
          triggerUpdate={() => setTriggerUpdate((trigger) => !trigger)}
        />
      ))}
      {createModalOpen && (
        <CreateCompetition
          onClose={() => setCreateModalOpen(false)}
          onUpdate={() => setTriggerUpdate((trigger) => !trigger)}
        />
      )}
    </PageWrapper>
  )
}

export default withAuthorization(Competitions)
