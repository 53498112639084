import React, { useState } from 'react'
import ImageUploader from '../../../components/ImageUploader'
import InputField from '../../../components/InputField'
import Modal from '../../../components/Modal'
import { Heading2Bold } from '../../../design-system/typography'
import { Button, TextButton } from '../../../design-system/utils'
import { Form, InputFields, Error } from './style'
import competitionsApi from '../../../api/competitions'
import { CompetitionStatus } from '../../../types/Competition'
import DropdownSelectorWrapper from '../../../components/DropdownSelectorWrapper/DropdownSelectorWrapper'
import LabeledField from '../../../types/LabeledField'
import DropdownSelector from '../../../components/DropdownSelector/DropdownSelector'

type CreateCompetitionProps = {
  onClose: () => void
  onUpdate: () => void
}

export const statusOptions = [
  {
    field: CompetitionStatus.active,
    label: 'Active',
  },
  {
    field: CompetitionStatus.archived,
    label: 'Archived',
  },
  {
    field: CompetitionStatus.inactive,
    label: 'Inactive',
  },
]

function CreateCompetition({ onClose, onUpdate }: CreateCompetitionProps) {
  const [thumbnail, setThumbnail] = useState(null)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [startDateTime, setStartDateTime] = useState(null)
  const [endDateTime, setEndDateTime] = useState(null)
  const [mainPrize, setMainPrize] = useState('')
  const [prizeQuantity, setPrizeQuantity] = useState(null)
  const [secondaryPrize, setSecondaryPrize] = useState('')
  const [url, setUrl] = useState('')
  const [status, setStatus] = useState<LabeledField>(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const submitReward = (e) => {
    e.preventDefault()

    competitionsApi
      .createCompetition({
        file: thumbnail as File,
        title,
        description,
        startDateTime,
        endDateTime,
        status: status?.field,
        mainPrize,
        secondaryPrize,
        prizeQuantity,
        url,
      })
      .then(() => {
        onUpdate()
        onClose()
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setErrorMessage(
            'Unable to save changes. Please make sure that all fields are filled.'
          )
        } else {
          setErrorMessage(
            err?.response?.data || 'Something went wrong. Please try again.'
          )
        }
      })
  }

  return (
    <Modal onClose={onClose} width="695px">
      <Heading2Bold>Create a competition</Heading2Bold>
      <Form onSubmit={submitReward}>
        <InputFields>
          <ImageUploader setImage={setThumbnail} />
          <div>
            <InputField placeholder="Title" onChangeEvent={setTitle} />
            <InputField
              placeholder="Description"
              onChangeEvent={setDescription}
            />
            <InputField
              placeholder="Start date"
              onChangeEvent={setStartDateTime}
              type="datetime-local"
            />

            <InputField
              placeholder="End date"
              onChangeEvent={setEndDateTime}
              type="datetime-local"
            />
            <InputField placeholder="Main Prize" onChangeEvent={setMainPrize} />
            <InputField
              placeholder="Prize Quantity"
              onChangeEvent={setPrizeQuantity}
              type="number"
            />

            <InputField
              placeholder="Secondary Prize"
              onChangeEvent={setSecondaryPrize}
            />

            <InputField placeholder="URL" onChangeEvent={setUrl} />
            <DropdownSelectorWrapper label="Status">
              <DropdownSelector
                currentSelection={status}
                options={statusOptions}
                onSelect={setStatus}
                placeholder="Select status"
              />
            </DropdownSelectorWrapper>
          </div>
        </InputFields>
        <Error>{errorMessage}</Error>
        <Button secondary type="submit">
          Create Competition
        </Button>
        <TextButton type="button" onClick={onClose}>
          Cancel
        </TextButton>
      </Form>
    </Modal>
  )
}
export default CreateCompetition
