import axios from 'axios'
import { TrainingPlan } from '../pages/UserTrainingPlans/TrainingPlansTable/utils'

const getAllTrainingPlans = () => {
  return axios.get('/api/admin/trainingplan/templates', {
    withCredentials: true,
  })
}

const getTemplate = () => {
  return axios.get('/api/admin/trainingplan/sampletemplate')
}

const uploadPlan = (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post('/api/admin/trainingplan/upload', formData, {
    withCredentials: true,
  })
}

const getUserTrainingPlans = (userId: number) => {
  return axios.get(`/api/admin/trainingplan/users/${userId}`)
}

const updateUserTrainingPlan = (
  userId: number,
  planId: number,
  plan: TrainingPlan
) => {
  return axios.put(`/api/admin/user/${userId}/plans/${planId}`, plan)
}

const trainingplansApi = {
  getAllTrainingPlans,
  getTemplate,
  uploadPlan,
  getUserTrainingPlans,
  updateUserTrainingPlan,
}

export default trainingplansApi
