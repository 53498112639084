export enum SportTypes {
  running = 'Running',
  strengthAndConditioning = 'Strength and Conditioning',
  general = 'General',
  rest = 'Rest',
}
export enum ActivityTypes {
  easyRun = 'Easy Run',
  longRun = 'Long Run',
  tempoRun = 'Tempo Run',
  intervalSession = 'Interval Session',
  strengthVideo = 'Strength Videos',
  strengthGifs = 'Strength GIFs',
  yoga = 'Yoga',
  rest = 'Rest',
  steadyRun = 'Steady Run',
  race = 'Race',
}
export enum SessionPhases {
  buildUp = 'Build Up',
  base = 'Base',
  strength = 'Strength',
  speed = 'Speed',
  taper = 'Taper',
}
export enum SessionStatus {
  hidden = 'hidden',
  active = 'active',
  replacedByTaper = 'replacedByTaper',
  taper = 'taper',
}

export type TrainingSessionInterval = {
  id: number

  title: string

  intervalNo: number

  goalTimeMs?: number

  goalDistanceMeters?: number

  goalPaceMsPerKm?: number

  goalPaceExpression?: string

  recordedTimeMs?: number

  recordedDistanceMeters?: number

  recordedPaceMsPerKm?: number

  trainingSessionId: number

  readonly createdAt: Date

  readonly updatedAt: Date
}

export enum TrainingPlanLevels {
  level1 = 'level1',
  level2 = 'level2',
  level3 = 'level3',
  level4 = 'level4',
}

export enum TrainingPlanStates {
  active = 'active',
  inactive = 'inactive',
  archived = 'archived',
  hidden = 'hidden',
}

export enum Distances {
  fivekm = '5km',
  tenkm = '10km',
  halfmarathon = 'halfmarathon',
  marathon = 'marathon',
  ultra_preparation = 'ultra_preparation',
  ultra_50km = 'ultra_50km',
  ultra_100km = 'ultra_100km',
  coopah_preparation = 'coopah_preparation',
  recovery = 'recovery',
  recovery_halfmarathon = 'recovery_halfmarathon',
  recovery_marathon = 'recovery_marathon',
}

export enum TrainingGoals {
  trainingForRace = 'Training for a race',
  runTargetTime = 'Run a target time',
  runFarther = 'Run farther',
  maintainFitness = 'Maintain fitness',
  getFaster = 'Get faster',
  runUltra = 'Run an ultra',
  recovery = 'Recovery Plan',
}

export type TrainingDays = {
  [ActivityTypes.longRun]: number[]
  [ActivityTypes.intervalSession]: number[]
  nonRunning?: number[]
  gymDay?: number[]
  rest?: number[]
}

export enum TrainingPlanType {
  recovery = 'recovery',
  taper = 'taper',
  taperExtended = 'taperExtended',
}

export type LevelInterview = {
  bestTargetDistance: Distances
  targetTimeMs: number
  longestRun: number
  bestRunTimeMs: number
}

export type TrainingPlan = {
  id: number

  userId: number

  targetDistance: Distances

  plannedWeeks: number

  targetRaceName: string

  startDate: Date

  targetDate: Date

  targetTimeMs: number

  avgPaceMsPerKm: number

  thrPaceMsPerKm: number

  status: TrainingPlanStates

  level: TrainingPlanLevels

  goal: TrainingGoals

  parentPlanId: number

  templatePlanId: number

  raceDistance: number

  raceDuration: number

  racePaceMsPerKm?: number

  raceDate: Date

  maxTrainingDays: number

  trainingDays: TrainingDays

  type: TrainingPlanType

  levelInterview: LevelInterview

  createdAt: Date

  updatedAt: Date
}

export type TrainingSession = {
  id: number
  title: string

  week: number

  day: number

  date: Date

  time?: string

  description?: string

  embedUrl?: string

  completed: boolean

  sportType?: SportTypes

  activityType?: ActivityTypes

  phase?: SessionPhases

  templateOrder?: number

  status?: SessionStatus

  trainingPlanId: number

  createdAt: Date

  updatedAt: Date

  //   thirdPartyWorkouts: ThirdPartyWorkouts[]

  //   trainingPlan: TrainingPlanModel

  intervals: TrainingSessionInterval[]
}

export const filterOptions = {
  activityType: Object.values(ActivityTypes),
  status: Object.values(SessionStatus),
  sportType: Object.values(SportTypes),
  phase: Object.values(SessionPhases),
  completed: [
    { key: true, title: '✅' },
    { key: false, title: '❌' },
  ],
}
