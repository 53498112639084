import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageWrapper } from '../../design-system/utils'
import { HeadingWrapper } from './style'
import { Heading1Bold, Paragraph2 } from '../../design-system/typography'
import trainingplansApi from '../../api/trainingplans'
import withAuthorization from '../../components/HOC/withAuthorization'
import TrainingPlansTable from './TrainingPlansTable'

const UserTrainingPlans = () => {
  const [plans, setPlans] = useState([])
  const [errorMessage, setErrorMessage] = useState(false)
  const { userId } = useParams()
  const fetchUserTrainingPlans = useCallback(async () => {
    try {
      const { data } = await trainingplansApi.getUserTrainingPlans(+userId)
      setPlans(data)
    } catch (err) {
      setErrorMessage(err.message)
    }
  }, [userId])

  useEffect(() => {
    fetchUserTrainingPlans()
  }, [fetchUserTrainingPlans, userId])

  const triggerUpdate = useCallback(() => {
    fetchUserTrainingPlans()
  }, [fetchUserTrainingPlans])

  return (
    <PageWrapper>
      <HeadingWrapper>
        <Heading1Bold>Training Plans</Heading1Bold>
      </HeadingWrapper>
      {errorMessage && <Paragraph2>{errorMessage}</Paragraph2>}
      <TrainingPlansTable
        plans={plans}
        userId={userId}
        triggerUpdate={triggerUpdate}
      />
    </PageWrapper>
  )
}

export default withAuthorization(UserTrainingPlans)
