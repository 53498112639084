import React, { useState } from 'react'
import moment from 'moment'
import competitionApi from '../../../api/competitions'
import EditDropdown from '../../../components/EditDropdown'
import {
  Heading2,
  Heading5,
  Paragraph2,
} from '../../../design-system/typography'
import EditCompetition from '../EditCompetition'
import { Card, TextWrapper, Image, ActionsWrapper } from './style'
import Competition, { CompetitionStatus } from '../../../types/Competition'

type RewardCardProps = {
  competition: Competition
  triggerUpdate: () => void
}

function CompetitionCard({ competition, triggerUpdate }: RewardCardProps) {
  const [modalOpen, setModalOpen] = useState(false)

  const deleteCompetition = () => {
    competitionApi.deleteCompetition(competition.id).then(() => triggerUpdate())
  }

  return (
    <Card>
      <ActionsWrapper>
        <EditDropdown
          editLabel="Edit"
          deleteLabel="Delete"
          deleteAction={deleteCompetition}
          editAction={() => setModalOpen(true)}
        />
      </ActionsWrapper>
      <Image src={competition.image} alt={competition.title} />
      <TextWrapper>
        <Heading2>{competition.title}</Heading2>
        <Heading5>{competition.description}</Heading5>

        <Paragraph2>
          {moment(competition?.startDateTime).format('YYYY-MM-DD HH:mm:ss')}{' '}
          &#8213;{' '}
          {moment(competition?.endDateTime).format('YYYY-MM-DD HH:mm:ss')}
        </Paragraph2>
        <Paragraph2>
          Status:{' '}
          <span
            style={{
              padding: '5px',
              borderRadius: '5px',
              fontSize: '12px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              backgroundColor:
                // eslint-disable-next-line no-nested-ternary
                competition?.status === CompetitionStatus.active
                  ? '#4caf50'
                  : competition?.status === CompetitionStatus.inactive
                  ? '#ffeb3b'
                  : '#f44336',
            }}
          >
            {competition?.status}
          </span>
        </Paragraph2>
      </TextWrapper>
      {modalOpen && (
        <EditCompetition
          competition={competition}
          onClose={() => setModalOpen(false)}
          onUpdate={triggerUpdate}
        />
      )}
    </Card>
  )
}
export default CompetitionCard
