import styled from 'styled-components'

export const Card = styled.div`
  width: 630px;
  min-height: 260px;
  margin-bottom: ${(p) => p.theme.spacing.md};
  box-shadow: ${(p) => p.theme.boxShadow};
  border-radius: ${(p) => p.theme.borderRadius};
  background: ${(p) => p.theme.colors.white};
  display: flex;
  position: relative;
  padding-right: ${(p) => p.theme.spacing.sm};
`

export const Image = styled.img`
  width: 260px;
  height: 100%;
  object-fit: cover;
  border-bottom-left-radius: ${(p) => p.theme.borderRadius};
  border-top-left-radius: ${(p) => p.theme.borderRadius};
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${(p) => p.theme.spacing.m};

  > a {
    margin-top: ${(p) => p.theme.spacing.sm};
    margin-bottom: ${(p) => p.theme.spacing.xs};
  }
`

export const ActionsWrapper = styled.div`
  position: absolute;
  top: ${(p) => p.theme.spacing.m};
  right: ${(p) => p.theme.spacing.m};
`
