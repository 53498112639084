import React, { useState } from 'react'
import {
  Heading3Bold,
  Paragraph2,
  ErrorMessage,
} from '../../../design-system/typography'
import { Button } from '../../../design-system/utils'
import { EditForm, CloseButton, DetailsCard } from './style'
import { TrainingPlan, TrainingPlanStates } from '../TrainingPlansTable/utils'
import trainingplansApi from '../../../api/trainingplans'
import DropdownSelectorWrapper from '../../../components/DropdownSelectorWrapper'
import DropdownSelector from '../../../components/DropdownSelector'
import CloseIcon from '../../../svgs/CloseIcon'

type EditPlanProps = {
  trainingPlan: TrainingPlan
  userId: number
  setTrainingPlan: (plan: TrainingPlan | null) => void
  trainingPlanIdWithActiveStatus: number | null
  triggerUpdate: () => void
}

const statusOptions = [
  { field: TrainingPlanStates.active, label: 'Active' },
  { field: TrainingPlanStates.inactive, label: 'Inactive' },
  { field: TrainingPlanStates.archived, label: 'Archived' },
  { field: TrainingPlanStates.hidden, label: 'Hidden' },
]

function EditPlan({
  trainingPlan,
  setTrainingPlan,
  trainingPlanIdWithActiveStatus,
  triggerUpdate,
  userId,
}: EditPlanProps) {
  const [planStatus, setPlanStatus] = useState(
    statusOptions?.find(({ field }) => field === trainingPlan.status)
  )

  const submitData = (e) => {
    e.preventDefault()
    const {
      status,
      startDate,
      targetDate,
      targetDistance,
      targetRaceName,
      targetTimeMs,
      avgPaceMsPerKm,
      trainingDays,
      goal,
      level,
      maxTrainingDays,
    } = trainingPlan
    trainingplansApi
      .updateUserTrainingPlan(userId, trainingPlan.id, {
        startDate,
        targetDate,
        targetDistance,
        targetRaceName,
        targetTimeMs,
        avgPaceMsPerKm,
        trainingDays,
        goal,
        level,
        maxTrainingDays,
        status: planStatus?.field || status,
      } as TrainingPlan)
      .then(() => {
        triggerUpdate()
        setTrainingPlan(null)
      })
  }

  const updateStatus = (val) => {
    setPlanStatus(val)
  }

  return (
    <>
      <DetailsCard>
        <CloseButton onClick={() => setTrainingPlan(null)}>
          <CloseIcon />
        </CloseButton>
        <EditForm onSubmit={submitData}>
          <DropdownSelectorWrapper label="Training Plan Status">
            <DropdownSelector
              currentSelection={planStatus}
              options={statusOptions}
              onSelect={updateStatus}
              placeholder="Select plan status"
            />
          </DropdownSelectorWrapper>
          {/* Prevent making 2 plans active */}
          {trainingPlanIdWithActiveStatus &&
          trainingPlanIdWithActiveStatus !== trainingPlan.id &&
          planStatus?.field === TrainingPlanStates.active ? (
            <ErrorMessage className="bg-red-200 py-3 px-4 rounded-xl font-bold text-xl">
              You are not able to make this plan active. Archive{' '}
              {trainingPlanIdWithActiveStatus} plan first
            </ErrorMessage>
          ) : null}
          <Button
            type="submit"
            disabled={
              trainingPlanIdWithActiveStatus &&
              trainingPlanIdWithActiveStatus !== trainingPlan.id &&
              planStatus?.field === TrainingPlanStates.active
            }
            className="disabled:cursor-not-allowed"
          >
            Save updates
          </Button>
        </EditForm>
      </DetailsCard>
    </>
  )
}

export default EditPlan
