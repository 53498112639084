import React, { useState, useMemo, Fragment } from 'react'
import {
  Column,
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  ColumnDef,
  flexRender,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table'
import Checkbox from '@mui/material/Checkbox'
import * as moment from 'moment'
import { Link } from 'react-router-dom'
import { TrainingPlan, TrainingPlanStates } from './utils'
import EditTrainingPlan from '../EditTrainingPlan'

interface IProps {
  plans: TrainingPlan[]
  userId: number
  triggerUpdate: () => void
}
export default function TrainingPlansTable({
  plans,
  userId,
  triggerUpdate,
}: IProps) {
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'startDate', desc: true },
  ])
  const [columnVisibility, setColumnVisibility] = useState({
    id: true,
    title: true,
    date: true,
    day: false,
    week: false,
    description: false,
    embedUrl: false,
    completed: true,
    sportType: false,
    activityType: true,
    phase: false,
    templateOrder: false,
    status: true,
    createdAt: false,
    updatedAt: false,
  })

  const trainingPlanIdWithActiveStatus = useMemo(
    () => plans.find((p) => p.status === TrainingPlanStates.active)?.id,
    [plans]
  )

  const [activeTrainingPlan, setActiveTrainingPlan] =
    useState<TrainingPlan | null>(null)

  const columns = React.useMemo<ColumnDef<TrainingPlan>[]>(
    () => [
      {
        accessorKey: 'id',
        enableColumnFilter: true,
        header: () => 'ID',
        filterFn: 'includesString',
        footer: (props) => props.column.id,
        cell: ({ row }) => {
          return (
            <Link
              to={`/users/${userId}/plans/${row.original.id}`}
              className="cursor-pointer underline"
            >
              {row.original.id}
            </Link>
          )
        },
      },
      {
        accessorKey: 'targetDistance',
        header: () => 'Distance',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'goal',
        header: () => 'Goal',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'day',
        header: () => 'Day',
        footer: (props) => props.column.id,
        // filterFn: 'includesString',
      },
      {
        accessorKey: 'plannedWeeks',
        header: () => 'Duration Weeks',
        footer: (props) => props.column.id,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'targetRaceName',
        header: () => 'Race Name',
        footer: (props) => props.column.id,
        // filterFn: 'includesString',
      },
      {
        accessorKey: 'startDate',
        header: () => 'Start Date',
        footer: (props) => props.column.id,
        // filterFn: 'includesString',
      },
      {
        accessorKey: 'targetDate',
        header: () => 'Target Date',
        footer: (props) => props.column.id,
        // filterFn: 'includesString',
      },
      {
        accessorKey: 'status',
        header: () => 'Status',
        footer: (props) => props.column.id,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'targetTimeMs',
        header: () => 'Target Time',
        cell: ({ row }) => {
          return moment.utc(row.original.targetTimeMs).format('HH:mm:ss')
        },
        footer: (props) => props.column.id,
        getCanFilter: () => false,
        // filterFn: 'includesString',
      },
      {
        accessorKey: 'avgPaceMsPerKm',
        header: () => 'Avg Pace',
        footer: (props) => props.column.id,
        cell: ({ row }) => {
          return moment.utc(row.original.avgPaceMsPerKm).format('mm:ss')
        },
        // filterFn: 'includesString',
      },
      {
        accessorKey: 'thrPaceMsPerKm',
        header: () => 'Threshold Pace',
        cell: ({ row }) => {
          return moment.utc(row.original.thrPaceMsPerKm).format('mm:ss')
        },
        footer: (props) => props.column.id,
        // filterFn: 'includesString',
      },
      {
        accessorKey: 'level',
        header: () => 'Level',
        footer: (props) => props.column.id,
        // filterFn: 'includesString',
      },
      {
        accessorKey: 'levelInterview',
        header: () => 'Level Interview Questions',
        footer: (props) => props.column.id,
        cell: ({ row }) => {
          return row.original.levelInterview
            ? JSON.stringify(row.original.levelInterview)
            : 'N/A'
        },
      },
      {
        accessorKey: 'trainingDays',
        header: () => 'Training Days',
        cell: ({ row }) => {
          return JSON.stringify(row.original.trainingDays)
        },
        footer: (props) => props.column.id,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'maxTrainingDays',
        header: () => 'Max Training Days',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'templatePlanId',
        header: () => 'Template Plan ID',
        footer: (props) => props.column.id,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'parentPlanId',
        header: () => 'Parent Plan ID',
        footer: (props) => props.column.id,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'raceDate',
        header: () => 'Race Date',
        footer: (props) => props.column.id,
        // filterFn: 'includesString',
      },
      {
        accessorKey: 'raceDistance',
        header: () => 'Race Distance',
        footer: (props) => props.column.id,
        // filterFn: 'includesString',
      },
      {
        accessorKey: 'raceDuration',
        header: () => 'Race Duration',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'racePaceMsPerKm',
        header: () => 'Race Pace',
        footer: (props) => props.column.id,
        // filterFn: 'includesString',
      },
    ],
    [userId]
  )

  const [expanded, setExpanded] = React.useState<ExpandedState>({})

  const table = useReactTable({
    data: plans,
    columns,
    state: {
      expanded,
      pagination: {
        pageSize: 1000,
        pageIndex: 0,
      },
      sorting,
      columnVisibility,
    },
    initialState: {
      sorting: [
        {
          id: 'startDate',
          desc: false,
        },
      ],
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    getRowCanExpand: (row) => {
      return false
    },

    debugTable: process.env.NODE_ENV === 'development',
  })

  return (
    <div className="p-2">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {activeTrainingPlan && (
              <EditTrainingPlan
                trainingPlan={activeTrainingPlan}
                userId={userId}
                setTrainingPlan={setActiveTrainingPlan}
                trainingPlanIdWithActiveStatus={trainingPlanIdWithActiveStatus}
                triggerUpdate={triggerUpdate}
              />
            )}
            <table className="min-w-full">
              <thead className="bg-white">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 text-center"
                        >
                          {header.isPlaceholder ? null : (
                            <div>
                              {/* eslint-disable-next-line */}
                              <div
                                className={
                                  header.column.getCanSort()
                                    ? 'flex justify-center cursor-pointer select-none flex-col'
                                    : 'flex justify-center'
                                }
                                onClick={() =>
                                  header.column.getToggleSortingHandler()
                                }
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {header.column.getCanFilter() ? (
                                  <div>
                                    <Filter column={header.column} />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr
                      key={row.id}
                      className="border-t border-gray-200 cursor-pointer hover:bg-gray-50"
                      onClick={() => setActiveTrainingPlan(row.original)}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className="bg-gray-50 py-2 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-3 text-center"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mt-4">{table.getRowModel().rows.length} Rows</div>
    </div>
  )
}

function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue()

  // if (column?.id === 'completed') {
  //   return (
  //     <Checkbox
  //       checked={!!columnFilterValue}
  //       onChange={(e) => column.setFilterValue(e.target.checked)}
  //     />
  //   )
  // }

  return (
    <>
      <input
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={(e) => {
          column.setFilterValue(e.target.value)
        }}
        placeholder="Search..."
        className="w-36 border shadow rounded"
      />
      <div className="h-1" />
    </>
  )
}
