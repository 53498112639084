import styled from 'styled-components'

export const CoopahLogo = styled.img`
  height: 150px;
  margin-bottom: 40px;
  max-width: 300px;
`

export const PageWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 20px;
  }

  h5 {
    margin-bottom: 15px;
  }
`

export const Heading5 = styled.h5`
  margin: 0;
  font-size: 18px;
  line-height: 130%;
  font-weight: 400;
  text-align: center;
`
