import styled from 'styled-components'
import { IconButton } from '../../../design-system/utils'

export const EditForm = styled.form`
  margin-top: ${(p) => p.theme.spacing.m};

  > div {
    margin-bottom: ${(p) => p.theme.spacing.sm};
  }

  > button {
    margin: ${(p) => p.theme.spacing.m} 0;
  }
`

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${(p) => p.theme.spacing.md};
  right: ${(p) => p.theme.spacing.md};
`

export const DetailsCard = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 475px;
  box-shadow: ${(p) => p.theme.boxShadow};
  background: ${(p) => p.theme.colors.white};
  z-index: 20;
  overflow: scroll;
  padding: ${(p) =>
    `${p.theme.spacing.xl} ${p.theme.spacing.md} ${p.theme.spacing.md}`};
`

export default EditForm
